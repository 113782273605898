




import Vue from 'vue'
import ClientForm from '@/views/Clients/views/ClientForm.vue'

export default Vue.extend({
  name: 'CreateClient',
  components: { ClientForm },
})
